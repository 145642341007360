import Head from "next/head";
import Link from "next/link";
import { type ReactElement } from "react";
import { FullWidthContainer } from "~/components/layout/Container";
import Layout from "~/components/layout/Layout";

const Custom404 = () => {
  return (
    <>
      <Head>
        <title>RFOX VALT | 404</title>
      </Head>
      <FullWidthContainer>
        <main className="flex w-full max-w-7xl flex-grow flex-col">
          <div className="my-auto flex-shrink-0 py-16 sm:py-32">
            <p className="text-xl font-semibold uppercase tracking-wide text-primary-100">
              404 error
            </p>
            <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-light-150 sm:text-5xl">
              Page not found
            </h1>
            <p className="mt-2 text-base text-gray-500">
              Sorry, we couldn&apos;t find the page you&apos;re looking for.
            </p>
            <div className="mt-6">
              <Link
                href="/"
                className="text-base font-medium text-primary-100 hover:text-primary-150"
              >
                Go back home<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </main>
      </FullWidthContainer>
    </>
  );
};

Custom404.getLayout = function getLayout(page: ReactElement) {
  return <Layout publicAccess={true}>{page}</Layout>;
};

export default Custom404;
